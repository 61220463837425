import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import 'jquery/dist/jquery.min';
import 'popper.js/dist/popper.min';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './src/styles/index.scss';

export const onClientEntry = () => {
    /* if (userHasOptedOutOfThirdPartyTracking()) {
    window[`ga-disable-${process.env.GATSBY_GOOGLE_ANALYTICS_ID`] = true
  } */

};

export const onRouteUpdate = () => {
  var _elqQ = _elqQ || [];
  _elqQ.push(['elqSetSiteId', '452605141']);
  _elqQ.push(['elqUseFirstPartyCookie', 'info.legal-solutions.thomsonreuters.co.uk']);
  _elqQ.push(['elqTrackPageView']);
  (function() {
      function async_load() {
          var s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://img.en25.com/i/elqCfg.min.js';
          var x = document.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
      }

      if (window.addEventListener) {
          window.addEventListener('DOMContentLoaded', async_load, false);
      } else if (window.attachEvent) {
          window.attachEvent('onload', async_load);
      }
  })();
}
